import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import './Products.scss';
import ProductService from "../../service/ProductService";
import {ProductItem} from "../../components/ProductItem/ProductItem";
import history from "../../service/History";
import {Category} from "../../types";
import Footer from "../../components/Footer/Footer";

export default function Products(): JSX.Element {
    const { id } = useParams<{id: string}>();
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState<Category>({
        id: 0,
        name: '',
        description: '',
        position: 0
    });

    useEffect(() => {
        ProductService.getProductsByCategory(id)
            .then(result => {
                if (result.error) {
                    return;
                }
                setCategory(result.data.category);
                console.log(result.data.category);
                setProducts(result.data.products);
            })
    }, [id]);

    if (products.length === 0) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <div className={'productPage'}>
            {
                category.image && (
                    <div className={'imageContainer'}>
                        <img src={`/assets/images/${category.image}`} alt=""/>
                    </div>
                )
            }
            <h1>{category.name}</h1>
            {category.description && <div className={'description'}>{category.description}</div> }
            <div className={'products'}>
                {products.map(({name, description, price}, index) => (
                    <ProductItem key={index} name={name} description={description} price={price}/>
                ))}
            </div>
            <Footer showBackButton={true} />
        </div>
    );
}
