import React, {ChangeEvent} from "react";

import './InputField.scss';

type Props = React.ComponentPropsWithRef<'input'> & {
    placeholder?: string;
    value?: string;
    type: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    pattern?: string;
}

export default function InputField(props: Props): JSX.Element {
    return (
        <input className={'inputField'} type={props.type} value={props.value} placeholder={props.placeholder}
            onChange={props.onChange} pattern={props.pattern}/>
    );
}

