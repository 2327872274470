import {mdiArrowLeftBold, mdiFacebook, mdiInstagram, mdiMenu} from '@mdi/js';
import Icon from "@mdi/react";
import React, {useState} from "react";
import  './Footer.scss';
import history from "../../service/History";

type Props = {
    showBackButton: boolean;
}

export default function Footer({ showBackButton }: Props): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);

    const goTo = (url: string) => {
        if (showMenu) {
            setShowMenu(false);
            window.open(url, '_blank');
        }
    }

    return (
        <div className={'footer'}>
            { showBackButton && (
                <span className={'bubble left'} onClick={() => history.goBack()}>
                    <Icon path={mdiArrowLeftBold} />
                </span>
            )}
            <span>
                <span className={'bubble right ' + (showMenu ? 'active' : '')} onClick={() => setShowMenu(!showMenu)}>
                    <Icon path={mdiMenu} />
                </span>
            </span>

            <span className={'iconContainer ' + (showMenu ? 'iconContainerActive' : '')}>
                <span onClick={() => goTo('https://www.instagram.com/rauchwolkelounge/')}><Icon className={'icon'} path={mdiInstagram} /></span>
                <span onClick={() => goTo('https://www.facebook.com/RauchwolkeLounge')}><Icon className={'icon'} path={mdiFacebook} /></span>
            </span>
        </div>
    );
}
