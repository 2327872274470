import React from "react";
import './CategoryBox.scss';
import history from "../../service/History";

type CategoryBoxProps = {
    id: number;
    image: string;
    name: string;
    hasChildren: boolean;
}

export function CategoryBox({id, image, name, hasChildren = false}: CategoryBoxProps): JSX.Element {
    const navigate = () => {
        if (hasChildren) {
            history.push(`/category/${id}`);
            return
        }
        history.push(`/product/${id}`);
    }
    return (
        <div className={'category'} onClick={() => navigate()}>
            <div className={'categoryBox'}>
                {
                    image && (
                        <div className={'categoryBoxImage'}>
                            <img src={`/assets/images/${image}`} alt={name}/>
                        </div>
                    )
                }
                <div className={'categoryBoxName'}>
                    <span>{ name }</span>
                </div>
            </div>

        </div>
    )
}