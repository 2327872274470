import React, {FormEvent, useEffect, useState} from "react";
import './Products.scss';
import CategoryService from "../../../service/CategoryService";
import ProductService from "../../../service/ProductService";
import history from "../../../service/History";
import InputField from "../../../components/Input/InputField";
import {Button} from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import {Category, Product} from "../../../types";
import {EditProductBox} from "../../../components/EditProductBox/EditProductBox";
import {DeleteProductBox} from "../../../components/DeleteProductBox/DeleteProductBox";
import {ReactSortable} from "react-sortablejs";
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';

export default function AdminProducts(): JSX.Element {
    const [products, setProducts] = useState<Product[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [categories, setCategories] = useState<Category[]>([]);
    const [newProductName, setNewProductName] = useState('');
    const [newProductDescription, setNewProductDescription] = useState('');
    const [newProductCost, setNewProductCost] = useState(0);
    const [newProductCategory, setNewProductCategory] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [editProduct, setEditProduct] = useState<Product>();
    const [isEditBoxOpen, setIsEditBoxOpen] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState<Product>();
    const [isDeleteBoxOpen, setIsDeleteBoxOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getProducts();
        CategoryService.getProductCategories()
            .then(result => {
                if (result.error) {
                    return;
                }
                setCategories(result.data.categories);
                setNewProductCategory(result.data.categories[0].name);
                setCategoryFilter(result.data.categories[0].id);
            });
    }, []);

    useEffect(() => {
        setFilteredProducts(
            products
                .filter(product => product.category.id === categoryFilter)
                .sort((a, b) => a.position - b.position)
        );
    }, [categoryFilter, products])

    const getProducts = () => {
        ProductService.getAllProducts()
            .then(result => {
                if (result.error) {
                    return;
                }
                setProducts(result.data.products);
            });
    }

    const create = async (event: FormEvent) => {
        event.preventDefault();

        if (newProductName === '' || newProductCost === 0 || newProductCategory === '') {
            setError(true);
            setMessage('Ein Feld ist leer');
            return;
        }

        const category = getCategoryId(newProductCategory);
        if (category.id === -1) {
            setError(true);
            setMessage('Keine Kategorie ausgewählt');
            return;
        }

        const res = await ProductService.createProduct({
            name: newProductName,
            description: newProductDescription,
            price: parseInt(String(newProductCost * 100)),
            categoryId: category.id
        });

        if (!res.error) {
            getProducts();
        }

        setError(res.error);
        setMessage(res.message);
    };

    const getCategoryId = (category: string) => {
        return categories.find(cat => cat.name === category) || {id: -1, name: category};
    };

    const edit = (product: Product) => {
        setEditProduct(product);
        setIsEditBoxOpen(true);
    };

    const activateDeleteProcess = (product: Product) => {
        setDeleteProduct(product);
        setIsDeleteBoxOpen(true);
    };

    const updatePosition = async () => {
        setIsLoading(true);
        await ProductService.updateProductPosition(filteredProducts);
        setIsLoading(false);
    };


    return (
        <div className={'adminProductsPage'}>
            {isLoading && <div className={'loading'}><div className={'loading-spinner'}></div></div>}
            {isEditBoxOpen && editProduct &&
                <EditProductBox product={editProduct} categories={categories} open={setIsEditBoxOpen} />
            }
            {isDeleteBoxOpen && deleteProduct &&
                <DeleteProductBox productId={deleteProduct.id} productName={deleteProduct.name}  open={setIsDeleteBoxOpen} />
            }
            <h1>Produkte</h1>
            {message !== '' && (
                <div className={error ? 'error' : 'success'}>
                    <p>{message}</p>
                </div>
            )}
            <form onSubmit={create}>
                <div>
                    <InputField type="text" placeholder="Produktname"
                                onChange={(e) => setNewProductName(e.target.value)}/>
                    <InputField type="text" placeholder="Beschreibung"
                                onChange={(e) => setNewProductDescription(e.target.value)}/>
                </div>
                <div>
                    <InputField type="text" placeholder="0.00" pattern="^[0-9]+[.]{1}[0-9]{2}|[^.][0-9]+$"
                                onChange={(e) => setNewProductCost(+e.target.value)} />
                    <Select objects={categories} object={newProductCategory} onChange={setNewProductCategory}/>
                </div>
                <Button text="Erstellen!" type="submit" />
            </form>
            <div className={'adminProducts'}>
                <select onChange={(event) => setCategoryFilter(+event.target.value)}>
                    {
                        categories.map((category, index) => (
                            <option value={category.id} key={index}>{category.name}</option>
                        ))
                    }
                </select>
                <ReactSortable tag="ul" list={filteredProducts} setList={setFilteredProducts}
                               onEnd={() => updatePosition()} handle={'.dragIcon'} animation={250}>
                    {filteredProducts.map(({name, description, price}, index) => (
                       <li key={index}>
                           <span>
                               <span className={'dragIcon'}><Icon path={mdiDrag} size={1} /></span>
                               <span>{ name }</span>
                           </span>
                           <div className={'actions'}>
                               <span onClick={() => edit(filteredProducts[index])}>✏️</span>
                               <span onClick={() => activateDeleteProcess(filteredProducts[index])}>🗑️</span>
                           </div>
                       </li>
                    ))}
                </ReactSortable>
            </div>
        </div>
    );
}
