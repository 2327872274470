import React, {FormEvent, useState} from "react";
import './EditCategoryBox.scss';
import {Category} from "../../types";
import InputField from "../Input/InputField";
import {Button} from "../Button/Button";
import history from "../../service/History";
import CategoryService from "../../service/CategoryService";


type EditBoxProps = {
    category: Category;
    open: (state: boolean) => void;
}

export function EditCategoryBox({category, open}: EditBoxProps): JSX.Element {
    const [categoryName, setCategoryName] = useState(category.name);
    const [categoryDescription, setCategoryDescription] = useState(category.description);

    const update = async (event: FormEvent) => {
        event.preventDefault();

        if (categoryName === '' || categoryDescription === '') {
            console.log("Felder sind leer");
            return
        }

        const res = await CategoryService.updateCategory(category.id, {
            name: categoryName,
            description: categoryDescription,
        });

        if (!res.error) {
            history.replace('/');
            history.push('./categories');
        }
    }

    return (
        <div className={'editContainer'}>
            <div className={'editBox'}>
                <b>{ categoryName }</b>
                <form onSubmit={update}>
                    <InputField type="text" placeholder="Produktname" value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}/>
                    <InputField type="text" placeholder="Beschreibung" value={categoryDescription}
                                onChange={(e) => setCategoryDescription(e.target.value)}/>
                    <Button text="Speichern!" type="submit" />
                    <Button text="Abbrechen" type="button" onClick={() => open(false)}/>
                </form>
            </div>
        </div>
    )
}