import React from "react";
import { BrowserRouter, Router as R, Switch, Route, Redirect } from "react-router-dom";
import history from "../service/History";
import Categories from "../pages/Categories/Categories";
import Products from "../pages/Products/Products";
import AdminCategories from "../pages/Admin/Categories/Categories";
import AdminProducts from "../pages/Admin/Products/Products";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";


export default class Router extends React.Component {
    render() {
        return (
            <R history={history}>
                <BrowserRouter>
                    <Switch>
                        <ProtectedRoute path="/admin" exact>
                            <Dashboard/>
                        </ProtectedRoute>
                        <Route path="/login" exact>
                            <Login/>
                        </Route>
                        <ProtectedRoute path='/admin/categories'>
                            <AdminCategories/>
                        </ProtectedRoute>
                        <ProtectedRoute path="/admin/products">
                            <AdminProducts/>
                        </ProtectedRoute>
                        <Route path="/product/:id">
                            <Products/>
                        </Route>
                        <Route path="/category/:id">
                            <Categories/>
                        </Route>
                        <Route path="/">
                            <Categories/>
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                </BrowserRouter>
            </R>
        );
    }
}

