import React from "react";

import './Button.scss';

interface IButtonProps {
    text: string;
    onClick?: React.MouseEventHandler;
    type: "button" | "submit" | "reset" | undefined;
}

export function Button(props: IButtonProps): JSX.Element {
    return (
        <button className={'button'} type={props.type} onClick={props.onClick}>{props.text}</button>
    );
}
