import React from "react";
import './DeleteCategoryBox.scss';
import {Button} from "../Button/Button";
import history from "../../service/History";
import CategoryService from "../../service/CategoryService";


type DeleteBoxProps = {
    categoryId: number;
    categoryName: string;
    open: (state: boolean) => void;
}

export function DeleteCategoryBox({categoryId, categoryName, open}: DeleteBoxProps): JSX.Element {

    const deleteCategory = async () => {
        const res = await CategoryService.deleteCategory(categoryId);

        if (!res.error) {
            history.replace('/');
            history.push('./categories');
        }
    }

    return (
        <div className={'deleteContainer'}>
            <div className={'deleteBox'}>
                <b>Löschen</b>
                <p>Willst du <b>"{ categoryName }"</b> wirklich löschen?</p>
                <Button text="Löschen!" type="submit" onClick={() => deleteCategory()} />
                <Button text="Abbrechen" type="button" onClick={() => open(false)} />
            </div>
        </div>
    )
}