import React from "react";

import './Select.scss';
import InputField from "../Input/InputField";

type Props = {
    objects: {name: string, id: number}[];
    onChange: (state: string) => void
    object: string;
}

export default function Select(props: Props): JSX.Element {
    return (
        <div className={'selectComponent'}>
            <InputField value={props.object}
                        onChange={(e) => props.onChange(e.target.value)}
                        type="text" />
            <div className={'categoriesList'}>
                <ul>
                    {
                        props.objects.map(({id, name}, index) => (
                            <li key={index} onClick={() => props.onChange(name)}>{name}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

