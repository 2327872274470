import React, {ReactElement, useEffect, useState} from "react";
import {Route, RouteProps} from "react-router-dom";
import {decodeToken} from "react-jwt";
import history from "../../service/History";

type ProtectedRouteProps = {
    children: ReactElement;
} & RouteProps

export default function ProtectedRoute({ children, ...props }: ProtectedRouteProps) {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        const token: string = localStorage.getItem('JWT') || '';
        if (token !== '') {
            if (decodeToken(token)) {
                setIsAuth(true);
            } else {
                history.push('/');
            }
        } else {
            history.push('/');
        }
    }, []);

    if (!isAuth) {
        return <></>;
    }

    return (
        <Route {...props}>
            {children}
        </Route>
    )
}