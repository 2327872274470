import React, {FormEvent, useEffect, useState} from "react";
import './Categories.scss';
import CategoryService from "../../../service/CategoryService";
import InputField from "../../../components/Input/InputField";
import {Button} from "../../../components/Button/Button";
import Icon from "@mdi/react";
import {mdiDrag} from "@mdi/js";
import {ReactSortable} from "react-sortablejs";
import {Category} from "../../../types";
import {DeleteCategoryBox} from "../../../components/DeleteCategoryBox/DeleteCategoryBox";
import {EditCategoryBox} from "../../../components/EditCategoryBox/EditCategoryBox";

export default function AdminCategories(): JSX.Element {
    const [categories, setCategories] = useState<Category[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryDescription, setNewCategoryDescription] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editCategory, setEditCategory] = useState<Category>();
    const [isEditBoxOpen, setIsEditBoxOpen] = useState(false);
    const [deleteCategory, setDeleteCategory] = useState<Category>();
    const [isDeleteBoxOpen, setIsDeleteBoxOpen] = useState(false);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (categoryFilter  === 0) {
            setFilteredCategories(
                categories
                    .filter(category => category.parentId === null)
                    .sort((a, b) => a.position - b.position)
            );
        } else {
            setFilteredCategories(
                categories
                    .filter(category => category.parentId === categoryFilter)
                    .sort((a, b) => a.position - b.position)
            );
        }
    }, [categoryFilter, categories]);

    const getCategories = () => {
        CategoryService.getCategories()
            .then(result => {
                if (result.error) {
                    return;
                }
                setCategories(result.data.categories);
            });
    }

    const create = async (event: FormEvent) => {
        event.preventDefault();

        if (newCategoryName === '') {
            setError(true);
            setMessage('Kategoriename ist leer');
            return;
        }

        const res = await CategoryService.createCategory({
            name: newCategoryName,
            description: newCategoryDescription
        });

        if (!res.error) {
            getCategories();
        }

        setError(res.error);
        setMessage(res.message);
    };

    const activateEditProcess = (category: Category) => {
        setEditCategory(category);
        setIsEditBoxOpen(true);
    }

    const activateDeleteProcess = (category: Category) => {
        setDeleteCategory(category);
        setIsDeleteBoxOpen(true);
    }

    const updatePosition = async () => {
        setIsLoading(true);
        await CategoryService.updateCategoryPosition(filteredCategories);
        setIsLoading(false);
    }


    return (
        <div className={'adminCategoriesPage'}>
            {isLoading && <div className={'loading'}><div className={'loading-spinner'}></div></div>}
            {
                isEditBoxOpen && editCategory &&
                <EditCategoryBox category={editCategory} open={setIsEditBoxOpen} />
            }
            {
                isDeleteBoxOpen && deleteCategory &&
                <DeleteCategoryBox categoryId={deleteCategory.id} categoryName={deleteCategory.name} open={setIsDeleteBoxOpen} />
            }
            <h1>Kategorien</h1>
            {message !== '' && (
                <div className={error ? 'error' : 'success'}>
                    <p>{message}</p>
                </div>
            )}
            <form onSubmit={create}>
                <InputField type="text" placeholder="Kategoriename"
                            onChange={(e) => setNewCategoryName(e.target.value)}/>
                <InputField type="text" placeholder="Beschreibung"
                            onChange={(e) => setNewCategoryDescription(e.target.value)}/>
                <Button text="Erstellen!" type="submit" />
            </form>

            <div className={'adminCategories'}>
                <select onChange={(event) => setCategoryFilter(+event.target.value)}>
                    <option value='0'>Hauptkategorien</option>
                    {
                        categories.filter(category => category.parentId === null).map((category, index) => (
                            <option value={category.id} key={index}>{category.name}</option>
                        ))
                    }
                </select>
                <ReactSortable tag="ul" list={filteredCategories} setList={setFilteredCategories}
                               onEnd={() => updatePosition()} handle={'.dragIcon'} animation={250}>
                    {filteredCategories.map(({name, id}, index) => (
                        <li key={index}>
                            <span>
                                <span className={'dragIcon'}><Icon path={mdiDrag} size={1} /></span>
                                <span>{ name }</span>
                            </span>
                            <div className={'actions'}>
                                <span onClick={() => activateEditProcess(filteredCategories[index])}>✏️</span>
                                <span onClick={() => activateDeleteProcess(filteredCategories[index])}>🗑️</span>
                            </div>
                        </li>
                    ))}
                </ReactSortable>
            </div>
        </div>
    );
}