import axios from "axios";
import {Category} from "../types";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

class CategoryService {
    async getCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getMainCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/main`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getProductCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/products`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getChildCategories(id: string): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/${id}/children`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async createCategory(category: {name: string, description: string}): Promise<any> {
        return new Promise(resolve => {
            axios.post(`/category/add`, category)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async updateCategory(id: number, category: {name: string,
        description: string}): Promise<any> {
        return new Promise(resolve => {
            axios.put(`/category/${id}`, category)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async updateCategoryPosition(categories: Category[]): Promise<any> {
        return new Promise(resolve => {
            axios.put(`/category/position`, categories)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async deleteCategory(id: number): Promise<any> {
        return new Promise(resolve => {
            axios.delete(`/category/${id}`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        })
    }
}

export default new CategoryService();