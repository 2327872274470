import React from "react";
import './ProductItem.scss';

type ProductItemProps = {
    name: string;
    description: string;
    price: number;
}

export function ProductItem({name, description, price}: ProductItemProps): JSX.Element {
    return (
        <div className={'product'}>
            <div className={'productItem'}>
                <span>{name}</span>
                {description && (<span>{description}</span>)}
            </div>
            <div className={'productPrice'}>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price/100.0)}</div>
        </div>
    )
}